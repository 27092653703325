<template>
  <div>
    <portal to="before-content" v-if="!breakpoints.greaterThanMd">
      <div class="filter-bar rfs-p-8-l">
        <font-awesome-icon icon="filter" />
        <button
          type="button"
          class="btn btn-text"
          :class="[{ active: smScreenControlsShow }]"
          @click="toggleControls()"
        >
          Filter NDCs <font-awesome-icon icon="angle-down" />
        </button>
      </div>
    </portal>
    <div class="tagline">
      Find Opportunity to Reduce Spend by Comparing Your Drug Prices to
      Real-World Data
    </div>

    <dl
      class="color-secondary rfs-30 fw-m rfs-m-8-b"
      style="margin-top: -16px"
      v-if="!breakpoints.greaterThanMd"
    >
      <dt class="inline">Actionable Opportunity&nbsp;</dt>
      <dd class="inline fw-b">
        <!-- double formating because animate number needs to be whole
        number to prevent dancing last digit -->
        <animated-number
          :number="
            totalOpportunity | formatNumber({ format: 'precision', places: 0 })
          "
          :numberFormat="{ format: 'dollar', places: 0 }"
        ></animated-number>
      </dd>
    </dl>

    <h3 class="content-subtitle">Explore NDCs with Savings Opportunity</h3>
    <transition-group name="fade">
      <div v-if="ndcPurchaseDetails.length === 0" key="1">
        <p
          class="card bg-warning-contrast color-white fw-600"
          v-if="!loadingData"
        >
          <font-awesome-icon class="" icon="exclamation-circle" />
          There are no NDC purchases that match your filtered criteria. Try
          making your search more broad.
        </p>
      </div>
      <div v-else class="grid grid-cols-1 xl:grid-cols-2 gap-4" key="2">
        <ndc-card
          v-for="(ndc, i) in ndcPurchaseDetails"
          :key="ndc.description.ndc"
          data-ndc-card
          :ndc="ndc.description.ndc"
          :ndcDesc="ndc.description.ndcDesc"
          :ndcsPurchased="ndcsPurchased(ndc.clientData)"
          :ndcTotalSpend="ndcTotalSpend(ndc.clientData)"
          :percentPurchasesByAcountTypeData="
            percentPurchasesByAcountTypeData(ndc)
          "
          :wtAvgCostPerNdcToggles="ndcPurchaseDetails[i].wtAvgCostPerNdcToggles"
          :wtAvgCostPerNdcData="
            wtAvgCostPerNdcData(ndcPurchaseDetails[i].wtAvgCostPerNdcData)
          "
          :wtAvgCostPerNdcVariant="ndcPurchaseDetails[i].wtAvgCostPerNdcVariant"
          :ndcOpportunity="ndcPurchaseDetails[i].ndcOpportunity"
          @adjust-weighted-avg-cost="adjustWeightedAvgCost($event, i)"
          :drugGpiCd="ndcPurchaseDetails[i].description.drugGpiCd"
          :drugGpiName="ndcPurchaseDetails[i].description.drugGpiName"
          @hideCards="hideAllNDC"
          :checked="checked"
          :ndcArray="ndcArray"
        />
      </div>
    </transition-group>
    <transition-group name="fade">
      <div v-if="loadingData" key="2" class="text-center rfs-m-8">
        <loader />
      </div>
      <!--- putting scroll loading on hold -->
      <!-- <scroll-loader
        key="3"
        :loader-method="loadNextPage"
        :loader-disable="loadingData"
      >
        <div></div>
      </scroll-loader> -->
      <div
        class="rfs-m-8-t text-center"
        v-if="ndcByOpportunity.length === 0 && ndcPurchaseDetails.length > 0"
        key="1"
      >
        <p>There are no more NDCs that match your filter criteria.</p>
      </div>
      <div
        v-else-if="ndcPurchaseDetails.length > 0"
        key="3"
        class="rfs-m-8-t text-center"
      >
        <button
          class="btn"
          :disabled="loadingData"
          @click="loadNextPage()"
          type="button"
        >
          Load More NDCs
        </button>
      </div>
    </transition-group>
    <div></div>
    <!-- content in portal after-content will be transported to div following appContent -->
    <portal to="after-content">
      <div
        class="right-side-bar"
        :class="{
          'sm-screen-contols-show':
            smScreenControlsShow && !breakpoints.greaterThanMd,
          'sm-screen-contols': !breakpoints.greaterThanMd
        }"
      >
        <div
          class="rsb-content auto-scroll"
          :class="{ 'has-footer': !breakpoints.greaterThanMd }"
        >
          <dl
            class="text-right color-secondary grid rfs-m-12-b"
            v-if="breakpoints.greaterThanMd"
          >
            <dt class="calloutLabel">Actionable Opportunity</dt>
            <dd class="callout">
              <animated-number
                :number="
                  totalOpportunity
                    | formatNumber({ format: 'precision', places: 0 })
                "
                :numberFormat="{ format: 'dollar', places: 0 }"
              ></animated-number>
            </dd>
          </dl>
          <form class="side-bar-controls" @submit.prevent="">
            <div :class="[{ 'form-disabled': loadingData }]">
              <date-range
                v-model="dateRange"
                class="rfs-m-8-b form-row"
                :disabled="loadingData"
                @input="onFilterChange()"
              />

              <multi-select-checkbox-dropdown
                :key="Math.random()"
                v-if="needsHospitalSelector"
                v-model="hospitalSegment"
                :options="hospitalListOptions"
                optionLabel="text"
                fieldLabel="Hospital"
                :clearable="false"
                class="form-group form-row"
                :disabled="loadingData"
                @input="onFilterChange()"
              />

              <single-select
                v-model="indexSegment"
                :options="isOptions"
                optionLabel="text"
                fieldLabel="Real World Data Segment"
                :clearable="false"
                class="form-group form-row"
                :disabled="loadingData"
                @input="onFilterChange()"
              />
            </div>
          </form>
          <form @submit.prevent="onFilterChange()">
            <h3 class="rfs-18 fw-sb rfs-m-4-b">
              Narrow Results by Drug Search Term
            </h3>
            <div
              class="single-input-search"
              :class="[{ 'form-disabled': loadingData }]"
            >
              <text-input
                :value="ndcLookup"
                fieldLabel="Narrow to NDCs Matching Search Term"
                type="search"
                class="rfs-m-t-b rfs-m-4-b"
                fieldNote="You may search by NDC, NDC description, GPI, or GPI description."
                :disabled="loadingData"
                @change="onFilterChange()"
                @input="
                  (v) => $store.commit('vr/dpbndc/ndc_lookup/setValue', v)
                "
              />

              <button
                class="btn btn-text search-form-submit"
                type="submit"
                title="search now"
              >
                <font-awesome-icon class="date-picker-icon" icon="search" />
              </button>
            </div>
          </form>
          <div>
            <h3 class="rfs-18 fw-sb rfs-m--b" style="text-align: right">
              Show Ignored Cards
              <input
                style="height: 20px; width: 20px"
                v-model="checked"
                type="checkbox"
                name="check"
              />
            </h3>
          </div>

          <div class="flex flex-row-reverse rfs-m-8-t">
            <button
              type="button"
              class="btn btn-primary"
              @click="hideAllCards()"
            >
              REFRESH
            </button>
          </div>
        </div>

        <div
          class="mobile-filter-footer flex items-center"
          v-if="!breakpoints.greaterThanMd"
        >
          <button
            type="button"
            class="btn btn-text float-right flex-last-to-right"
            @click="toggleControls()"
          >
            View Results
          </button>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex';
  import AnimatedNumber from '@/components/base/animated_number.vue';

  // helpers
  import map from 'lodash/map';
  import sumBy from 'lodash/sumBy';
  import {
    formatNumber,
    calculatePercent,
    weightedAvg,
    percentOfSum
  } from '@/js/utilities';

  // data
  import { defaultDateRange, hospitalList } from '@/data';

  // components
  // cannot be lazy loaded because of weird store hack
  import selectStore from '@/store/modules/global/filters/select';
  import singleSelect from '@/components/base/forms/single_select';
  import dateRange from '@/components/base/forms/date_range';
  import TextInput from '@/components/base/forms/text_input.vue';
  import multiSelectCheckboxDropdown from '@/components/base/forms/multi_select_checkbox_dropdown';

  const ndcCard = () =>
    import(
      /* webpackPrefetch: true */ '@/components/variancereports/ndcCard/ndcCard.vue'
    );

  export default {
    name: 'DrugPricingByNdc',
    components: {
      singleSelect,
      dateRange,
      TextInput,
      AnimatedNumber,
      ndcCard,
      multiSelectCheckboxDropdown
    },
    data() {
      return {
        noContent: true,
        actionableOpportunity: '-',
        hospitalListOptions: [],
        defaultHospitalName: false,
        defaultHospitalId: false,
        smScreenControlsShow: false,
        requestingTheseNdcs: '',
        checked: false,
        dataArray: [],
        ndcArray: []
      };
    },
    computed: {
      ...mapState('ndcOpportunity', {
        totalOpportunity: (state) => state.totalOpportunity,
        loadingTotalOpportunity: (state) => state.loadingTotalOpportunity,
        ndcByOpportunity: (state) => state.ndcByOpportunity,
        loadingNdcByOpportunity: (state) => state.loadingNdcByOpportunity,
        ndcPurchaseDetails: (state) => state.ndcPurchaseDetails,
        loadingNdcPurchaseDetails: (state) => state.loadingNdcPurchaseDetails
      }),
      ...mapState('vr/dpbndc/hospital_segment', {
        hospitalIds: (state) =>
          state.value !== null && state.value.length > 0
            ? state.value.map((hsp) => hsp.value).reduce((a, r) => a + ',' + r)
            : ''
      }),
      ...mapState('vr/dpbndc/date_range', {
        startDate: (state) => state.value.start,
        endDate: (state) => state.value.end
      }),
      ...mapState('vr/dpbndc/index_segment', {
        comparisonIndex: (state) => state.value.value
      }),
      ...mapState('vr/dpbndc/ndc_lookup', {
        ndcLookup: (state) => state.value.value
      }),
      ...mapState('auth', {
        selectedHospitalList: (state) => state.selectedHospitalList
      }),
      isOptions() {
        return this.$store.getters['auth/userDataSegments'];
      },
      isDefault() {
        return this.isOptions.length > 0 ? this.isOptions[0] : {};
      },
      loadingData() {
        return (
          this.loadingTotalOpportunity ||
          this.loadingNdcByOpportunity ||
          this.loadingNdcPurchaseDetails
        );
      },
      needsHospitalSelector() {
        return this.hospitalListOptions.length >= 2;
      },
      hospitalSegmentDefault() {
        return {
          text: this.defaultHospitalName,
          value: this.defaultHospitalId
        };
      },
      dateRange: {
        get() {
          return this.$store.getters['vr/dpbndc/date_range/getValue'];
        },
        set(value) {
          return this.$store.commit('vr/dpbndc/date_range/setValue', value);
        }
      },
      hospitalSegment: {
        get() {
          return this.$store.getters['vr/dpbndc/hospital_segment/getValue'];
        },
        set(value) {
          this.$store.commit('auth/setSelectedHospitalList', value);
          return this.$store.commit(
            'vr/dpbndc/hospital_segment/setValue',
            value
          );
        }
      },
      indexSegment: {
        get() {
          return this.$store.getters['vr/dpbndc/index_segment/getValue'];
        },
        set(value) {
          return this.$store.commit('vr/dpbndc/index_segment/setValue', value);
        }
      },
      accountTypes: {
        get() {
          return this.$store.getters['vr/dpbndc/account_types/getValue'];
        },
        set(value) {
          return this.$store.commit('vr/dpbndc/account_types/setValue', value);
        }
      },
      codeType: {
        get() {
          return this.$store.getters['vr/dpbndc/code_type/getValue'];
        },
        set(value) {
          return this.$store.commit('vr/dpbndc/code_type/setValue', value);
        }
      },
      ndcLookup: {
        get() {
          return this.$store.getters['vr/dpbndc/ndc_lookup/getValue'];
        },
        set(value) {
          return this.$store.commit('vr/dpbndc/ndc_lookup/setValue', value);
        }
      },
      requestData() {
        return {
          comparisonIndex: this.comparisonIndex,
          endDate: this.endDate,
          hospitalIds: this.hospitalIds, // array to comma sep string
          startDate: this.startDate,
          accountType: 'GPO', // array to comma sep string - defaults to just gpo
          searchString: this.ndcLookup
        };
      },
      currentOffset() {
        return this.ndcPurchaseDetails.length;
      },
      requestDataWithPaging() {
        return {
          ...this.requestData,
          offset: this.currentOffset
        };
      },
      ndcPurchaseDetailsRequestData() {
        return {
          comparisonIndex: this.comparisonIndex,
          endDate: this.endDate,
          hospitalIds: this.hospitalIds, // array to comma sep string
          startDate: this.startDate,
          ndcs: map(this.ndcByOpportunity, 'ndc').join(',')
        };
      },
      filtersReady() {
        if (
          this.comparisonIndex &&
          this.endDate &&
          this.hospitalIds &&
          this.startDate
        ) {
          return true;
        }
        return false;
      }
    },
    methods: {
      ...mapActions('ndcOpportunity', [
        'requestTotalOpportunity',
        'requestNdcByOpportunity',
        'requestNdcPurchaseDetails'
      ]),
      ...mapMutations('ndcOpportunity', [
        'setTotalOpportunity',
        'setLoadingTotalOpportunity',
        'setNdcByOpportunity',
        'setLoadingNdcByOpportunity',
        'setNdcPurchaseDetails',
        'addResultsToNdcPurchaseDetails',
        'setLoadingNdcPurchaseDetails'
      ]),
      zeroOutNonNumbers(val) {
        if (isNaN(val) || !(typeof val === 'number')) {
          return 0;
        }
        return val;
      },
      adjustWeightedAvgCost(payload, i) {
        this.ndcPurchaseDetails[i].wtAvgCostPerNdcToggles = payload;
        const clientDataSelected = [];
        const rwdDataSelected = [];
        for (let item in this.ndcPurchaseDetails[i].wtAvgCostPerNdcToggles) {
          const accountType =
            this.ndcPurchaseDetails[i].wtAvgCostPerNdcToggles[item];

          if (this.ndcPurchaseDetails[i].clientData[accountType]) {
            clientDataSelected.push(
              this.ndcPurchaseDetails[i].clientData[accountType]
            );
          }
          if (this.ndcPurchaseDetails[i].varianceData[accountType]) {
            rwdDataSelected.push(
              this.ndcPurchaseDetails[i].varianceData[accountType]
            );
          }
        }

        let clientTotal = weightedAvg(clientDataSelected, 'avgSpend', 'qty');
        let rwdTotal = weightedAvg(rwdDataSelected, 'avgSpend', 'qty');

        this.ndcPurchaseDetails[i].wtAvgCostPerNdcVariant =
          ((clientTotal - rwdTotal) / rwdTotal) * 100;

        if (clientTotal < rwdTotal) {
          this.ndcPurchaseDetails[i].ndcOpportunity = null;
        } else {
          this.ndcPurchaseDetails[i].ndcOpportunity =
            (clientTotal - rwdTotal) * sumBy(clientDataSelected, 'qty');
        }

        this.ndcPurchaseDetails[i].wtAvgCostPerNdcData = [
          formatNumber(clientTotal, { format: 'precision', places: 0 }),
          formatNumber(rwdTotal, { format: 'precision', places: 0 })
        ];
      },
      wtAvgCostPerNdcData(data) {
        return {
          labels: ['You', 'RWD'],
          datasets: [
            {
              data: data,
              backgroundColor: [
                this.crcaColors.dataVis1,
                this.crcaColors.dataVis17
              ]
            }
          ]
        };
      },
      percentPurchasesByAcountTypeData(ndcDetails) {
        const totalQtyClient = this.totalSegmentDataPoints(
          ndcDetails.clientData,
          'qty'
        );
        const totalQtyRwd = this.totalSegmentDataPoints(
          ndcDetails.varianceData,
          'qty'
        );

        const gpoClientPercent = calculatePercent(
          ndcDetails.clientData.gpo ? ndcDetails.clientData.gpo.qty : 0,
          totalQtyClient
        );
        const gpoRwdPercent = calculatePercent(
          ndcDetails.varianceData.gpo ? ndcDetails.varianceData.gpo.qty : 0,
          totalQtyRwd
        );

        const tfbClientPercent = calculatePercent(
          ndcDetails.clientData['340b'] ? ndcDetails.clientData['340b'].qty : 0,
          totalQtyClient
        );
        const tfbRwdPercent = calculatePercent(
          ndcDetails.varianceData['340b']
            ? ndcDetails.varianceData['340b'].qty
            : 0,
          totalQtyRwd
        );

        const wacClientPercent = calculatePercent(
          ndcDetails.clientData.wac ? ndcDetails.clientData.wac.qty : 0,
          totalQtyClient
        );
        const wacRwdPercent = calculatePercent(
          ndcDetails.varianceData.wac ? ndcDetails.varianceData.wac.qty : 0,
          totalQtyRwd
        );

        const clientPercentTo100 = percentOfSum([
          this.zeroOutNonNumbers(gpoClientPercent),
          this.zeroOutNonNumbers(tfbClientPercent),
          this.zeroOutNonNumbers(wacClientPercent)
        ]);
        const rwdPercentTo100 = percentOfSum([
          this.zeroOutNonNumbers(gpoRwdPercent),
          this.zeroOutNonNumbers(tfbRwdPercent),
          this.zeroOutNonNumbers(wacRwdPercent)
        ]);

        // GPO account is called Index in the UI
        const gpo = [clientPercentTo100[0], rwdPercentTo100[0]];
        const threeFourtyB = [clientPercentTo100[1], rwdPercentTo100[1]];
        const wac = [clientPercentTo100[2], rwdPercentTo100[2]];

        return {
          labels: ['You', 'RWD'],
          datasets: [
            {
              label: 'Index',
              data: gpo,
              backgroundColor: [
                this.crcaColors.dataVis1,
                this.crcaColors.dataVis17
              ]
            },
            {
              label: 'WAC',
              data: wac,
              backgroundColor: [
                this.crcaColors.dataVis2,
                this.crcaColors.dataVis16
              ]
            },
            {
              label: '340B',
              data: threeFourtyB,
              backgroundColor: [
                this.crcaColors.dataVis3,
                this.crcaColors.dataVis15
              ]
            }
          ]
        };
      },
      totalSegmentDataPoints(segmentData, prop) {
        let total = 0;
        for (const accountType in segmentData) {
          total = total + segmentData[accountType][prop];
        }
        return total;
      },
      ndcsPurchased(segmentData) {
        return this.totalSegmentDataPoints(segmentData, 'qty');
      },
      ndcTotalSpend(segmentData) {
        return this.totalSegmentDataPoints(segmentData, 'totalSpend');
      },
      toggleControls() {
        this.smScreenControlsShow = !this.smScreenControlsShow;
      },
      formatUiDetails(resp) {
        let ndcDetailsByOpp = this.ndcByOpportunity.map((ndcOrder) => {
          const item = resp.find(
            (ndcDetail) => ndcDetail.description.ndc === ndcOrder.ndc
          );
          if (!item) {
            // these two lists should always have the same ndcs. If they don't something is wrong on the data side. So tossing a warning to let the FEDs know to talk to the API devs.
            console.warn(
              'ndcPurchaseDetails results does not contain ' +
                ndcOrder.ndc +
                ", however it was included in the response from the ndcByOpportunity end point. Check with your friendly neighborhood API dev to figure out we don't have matching ndcs returned. The UI is dropping the NDC from the details order so this function doesn't error out and fail to produce any cards."
            );
            return;
          }
          return item;
        });

        // If we had a mismatch we need to remove the undefined items from our array before we map again to keep from killing the cards we do have and fail as gracefully as possible for the user.
        ndcDetailsByOpp = ndcDetailsByOpp.filter(function (item) {
          return typeof item !== 'undefined';
        });

        return ndcDetailsByOpp.map((obj) => {
          let ndcOpportunity;
          if (obj.clientData.gpo.avgSpend < obj.varianceData.gpo.avgSpend) {
            ndcOpportunity = null;
          } else {
            ndcOpportunity =
              (obj.clientData.gpo.avgSpend - obj.varianceData.gpo.avgSpend) *
              obj.clientData.gpo.qty;
          }
          return {
            ...obj,
            wtAvgCostPerNdcToggles: ['gpo'],
            wtAvgCostPerNdcData: [
              formatNumber(obj.clientData.gpo.avgSpend, {
                format: 'precision',
                places: 0
              }),
              formatNumber(obj.varianceData.gpo.avgSpend, {
                format: 'precision',
                places: 0
              })
            ],
            wtAvgCostPerNdcVariant:
              ((obj.clientData.gpo.avgSpend - obj.varianceData.gpo.avgSpend) /
                obj.varianceData.gpo.avgSpend) *
              100,
            ndcOpportunity: ndcOpportunity
          };
        });
      },
      onFilterChange() {
        if (
          // we have all required filters
          this.filtersReady &&
          !this.loadingData
        ) {
          this.setNdcPurchaseDetails([]);
          this.requestTotalOpportunity({ requestData: this.requestData }).catch(
            () => {
              this.setTotalOpportunity(0);
            }
          );
          this.requestNdcByOpportunity({ requestData: this.requestData })
            .then((resp) => {
              this.setNdcByOpportunity(resp);
            })
            .finally(() => {
              if (this.ndcByOpportunity.length > 0) {
                this.requestNdcPurchaseDetails({
                  requestData: this.ndcPurchaseDetailsRequestData
                }).then((resp) => {
                  const uiDetails = this.formatUiDetails(resp);
                  this.setNdcPurchaseDetails(uiDetails);
                });
              }
            });
        }
      },
      hideAllNDC(ndcId, status) {
        if (status) this.dataArray.push(ndcId);
        else this.dataArray.splice(this.dataArray.indexOf(ndcId), 1);
      },
      hideAllCards() {
        this.ndcArray = this.dataArray.slice();
        const requestData = {
          dataArray: this.dataArray,
          accountId: this.$store.getters['auth/getUserAccountId'],
          hospitalIds: this.hospitalIds
        };
        this.$store
          .dispatch('ndcOpportunity/requestNdcCardId', requestData)
          .then((res) => {
          });
      },
      retrieveNdcCards() {
        const requestData = {
          accountId: this.$store.getters['auth/getUserAccountId']
        };
        this.$store
          .dispatch('ndcOpportunity/requestRetriveNdcIds', requestData)
          .then((res) => {
            this.ndcArray = JSON.parse(res.ndcs);
            this.dataArray = this.ndcArray.map((ndc) => ndc);
          });
      },
      loadNextPage() {
        // only try to load new cards if:
        // we have the required filters
        // we are not loading things
        // we we have loaded results but our last offset returned empty
        if (
          this.filtersReady &&
          !this.loadingData &&
          this.ndcByOpportunity.length > 0
        ) {
          this.requestNdcByOpportunity({
            requestData: this.requestDataWithPaging
          })
            .then((resp) => {
              this.setNdcByOpportunity(resp);
            })
            .finally(() => {
              if (this.ndcByOpportunity.length > 0) {
                this.requestNdcPurchaseDetails({
                  requestData: this.ndcPurchaseDetailsRequestData
                }).then((resp) => {
                  const uiDetails = this.formatUiDetails(resp);
                  this.addResultsToNdcPurchaseDetails(uiDetails);
                });
              }
            });
        }
      }
    },
    watch: {
      filtersReady() {
        this.onFilterChange();
      },
      selectedHospitalList(v) {
        if (v !== undefined && v !== null && v.length > 0) {
          this.hospitalSegment = v;
          this.onFilterChange();
        }
      }
    },
    beforeCreate() {
      // need to move all this to the same store, magic modules arent' the right tool here, but for now working around it to do other things
      window.vm.magicModule('vr/dpbndc/ndc_lookup', selectStore);
      window.vm.magicModule('vr/dpbndc/index_segment', selectStore);
      window.vm.magicModule('vr/dpbndc/date_range', selectStore);
      window.vm.magicModule('vr/dpbndc/hospital_segment', selectStore);
      if (!this.$store.getters['vr/dpbndc/hospital_segment/getValue']) {
        hospitalList().then((hospitals) => {
          let selectedHospitalList =
            this.$store.getters['auth/getSelectedHospitalList'];
          if (
            selectedHospitalList !== undefined &&
            selectedHospitalList !== null &&
            selectedHospitalList.length > 0
          ) {
            this.$store.commit(
              'vr/dpbndc/hospital_segment/setValue',
              selectedHospitalList
            );
          } else {
            window.vm.$store.commit('vr/dpbndc/hospital_segment/setValue', [
              {
                text: hospitals[0].text,
                value: hospitals[0].value
              }
            ]);
          }
        });
      }
      if (!this.$store.getters['vr/dpbndc/index_segment/getValue']) {
        let dataSegs = this.$store.getters['auth/userDataSegments'];

        window.vm.$store.commit(
          'vr/dpbndc/index_segment/setValue',
          dataSegs.length > 0 ? dataSegs[0] : {}
        );
      }
      if (!this.$store.getters['vr/dpbndc/date_range/getValue']) {
        window.vm.$store.commit(
          'vr/dpbndc/date_range/setValue',
          defaultDateRange
        );
      }
    },
    created() {
      hospitalList().then((list) => {
        this.hospitalListOptions = list;
      });
    },
    mounted() {
      this.retrieveNdcCards();
    },
    beforeDestroy() {
      this.hideAllCards();
      // Unregister the dynamically created module
      // want to use them between pages, so should not unregister now
      // ToDO - Add these into the normal state object for this page
      if (this.$store.hasModule('vr/dpbndc/hospital_segment')) {
        this.$store.unregisterModule('vr/dpbndc/hospital_segment');
      }
    }
  };
</script>

<style lang="scss">
  @use '@/assets/scss/responsive_grid.scss' as rg;
  @use '@/assets/scss/margin_padding.scss' as mp;
  .drug-pricing-by-ndc {
    #appContent {
      min-height: calc(100vh - 220px);
    }
    .card-percent-bar {
      span {
        display: inline-block;
        min-width: 20px;
      }
      .percent-bar {
        @extend .rfs-p-2;
      }
    }

    .percent-by-account-type-chart {
      height: 100px;
    }

    .card:not(.small) {
      .card-subtitle {
        @extend .grid;
        @extend .grid-cols-2;
      }
      .account-toggles {
        @extend .grid;
        @extend .grid-cols-3;
      }
    }

    .card.small {
      .card-subtitle {
        div {
          @extend .rfs-m-2-b;
        }
      }
      .account-toggles {
        label {
          @extend .rfs-m-2-b;
        }
      }
    }

    .grid .callout {
      grid-row: 1;
    }
  }
</style>
